import {React,useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import { GraphicWalker } from '@kanaries/graphic-walker';
import axios from 'axios'

function GraphComponent() {
  const { objectname, category, fields, sfid } = useParams();

  const [showGraphicwalker,setShowGraphicWalker] = useState(false)
  const [fieldsArray,setfieldsArray] = useState([])
  const [dataSource,setdataSource] = useState([])

  useEffect(()=>{

    console.log("use effect console: ",objectname, category, fields,sfid)
    datacall(objectname, category, fields, sfid)

    },[])

    const datacall = async (objectname, category, fields,sfid)=>{
      if(objectname && category && fields && sfid){
        try{
        const pivottableServerIP = "https://ml.loglens.io:5002/csv-fetch-data"
        // const pivottableServerIP = "http://127.0.0.1:5002/csv-fetch-data"
        const selectedArray = fields.split(",")
        const selectedd = selectedArray.map((item)=>{
            return({
                fid: item,
                semanticType: 'quantitative',
                analyticType: 'dimension',
            })
        })

        setfieldsArray(selectedd)

        const objectData = {
            columns_to_project: selectedArray,
            subcategory: category,
            sf_organization_id: sfid,
            object_name: objectname
           }

        const Resdata = await axios.post(pivottableServerIP,objectData)
        console.log(Resdata.data,selectedArray)
        setdataSource(Resdata.data)
        setShowGraphicWalker(true)

             setTimeout(() => {


                
                const shadowRoot = document.querySelector('#my-gw-component').querySelector('div').shadowRoot;
                console.log(shadowRoot)
                const style = document.createElement('style');
                style.textContent = '[role="tablist"] { display:none; } .text-xs { font-size: 0.95rem;} .my-1 {color:#1276b1f3} [data-state=on] {background-color : #1276b1f3 !important; } .my-1 > div:last-child {display:none}';  
                shadowRoot.appendChild(style);

            }, 10);
 
             
    }catch(e){
        console.log(e)
    }
  }
}
  return (
    <div id='my-gw-component' className='maingwdiv'>
      {showGraphicwalker?
     <GraphicWalker
        data={dataSource}
        fields={fieldsArray}
        appearance='light'
        
        // spec={graphicWalkerSpec}
        // i18nLang={langStore.lang}
    />
       : '' }
    </div>
  );
}

export default GraphComponent;
