import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GraphComponent from"./GraphComponent.js"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:objectname/:category/:fields/:sfid" element={<GraphComponent />} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;